<template>
    <!--UPLOAD-->
    <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
        <!-- <h1>Upload images</h1> -->
        <div class="dropbox">
            <input @click="reset()" type="file" multiple :name="uploadFieldName" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                accept="image/*, video/*, audio/*" class="input-file">
            <div>
                <p v-if="isInitial">
                    <b>Solte aqui o(s) arquivo(s)<br> ou clique para pesquisar</b>
                </p>
                <p v-if="isSaving">
                    <b>Você selecionou {{ fileCount }} arquivo(s) para enviar...</b>
                </p>
                <ol v-if="isSaving">
                    <li v-for="file in list" :key="file">{{file}}</li>
                </ol>
            </div>
        </div>
    </form>
</template>

<!-- Javascript -->
<script>
  const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
  export default {
    name: 'file-input',
    data() {
      return {
        uploadedFiles: [],
        uploadError: null,
        currentStatus: null,
        uploadFieldName: 'photos',
        fileCount: 0,
        list: []
      }
    },
    computed: {
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      }
    },
    methods: {
      reset() {
        // reset form to initial state
        this.currentStatus = STATUS_INITIAL;
        this.uploadedFiles = [];
        this.uploadError = null;
      },
      save(formData) {
        this.currentStatus = STATUS_SAVING;
        console.log(formData)
      },
      filesChange(fieldName, fileList) {
        // handle file changes
        // const formData = new FormData();
        const files = []

        if (!fileList.length) return;

        // append the files to FormData
        Array
          .from(Array(fileList.length).keys())
          .map(x => {
            files.push(fileList[x])
            this.list.push(fileList[x].name)
          });

        // emit event
        this.$emit('files:change', files)
        this.currentStatus = STATUS_SAVING;
      }
    },
    mounted() {
      this.reset();
    },
  }
</script>

<!-- SASS styling -->
<style>
  .dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: lightgrey;
    color: dimgray;
    padding: 0px 0px;
    margin-top: 15px;
    min-height: 100px; /* minimum height */
    width: 100%;
    position: relative;
    cursor: pointer;
  }
  
  .input-file {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 100px;
    position: absolute;
    cursor: pointer;
  }
  
  .dropbox:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
  }
  
  .dropbox ol {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
  .dropbox div {
    font-size: 1.2em;
    text-align: center;
    padding: 30px 0;
  }
</style>