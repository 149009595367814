<template>
  <div class="container p-centered">
    <h2 class="text-light page-title">ESCOLHA O TIPO DA DENÚNCIA</h2>
    <div class="columns">
      <div class="column col-3 text-box box-secondary mx-2" @click="$emit('nextPage', 4, {id: 82, title: 'TRÁFICO DE DROGAS'})">
        <p class="m-0">TRÁFICO DE DROGAS</p>
      </div>
      <div class="column col-3 text-box box-secondary mx-2" @click="$emit('nextPage', 4, {id: 78, title: 'ROUBO/FURTO'})">
        <p class="m-0">ROUBO</p><p class="m-0">-------</p><p class="m-0">FURTO</p>
      </div>
      <div class="column col-3 text-box box-secondary mx-2" @click="$emit('nextPage', 4, {id: 55, title: 'HOMICÍDIO'})">
        <p class="m-0">HOMICÍDIO</p>
      </div>
    </div>
    <div class="columns">
      <div class="column col-3 text-box box-secondary mx-2" @click="$emit('nextPage', 4, {id: 85, title: 'VIOLÊNCIA CONTRA A MULHER'})">
        <p class="m-0">VIOLÊNCIA CONTRA A MULHER</p>
      </div>
      <div class="column col-3 text-box box-secondary mx-2" @click="$emit('nextPage', 4, {id: 49, title: 'FORAGIDO/FUGITIVO'})">
        <p class="m-0">FORAGIDO</p><p class="m-0">-------</p><p class="m-0">FUGITIVO</p>
      </div>
      <div class="column col-3 text-box box-secondary mx-2" @click="$emit('nextPage', 4, {id: 30, title: 'CRIMES CONTRA MENORES'})">
        <p class="m-0">CRIMES CONTRA MENORES</p>
      </div>
    </div>
    <div class="columns">
      <div class="column col-3 text-box box-secondary mx-2" @click="$emit('nextPage', 4, {id: 22, title: 'CRIMES CONTRA O IDOSO'})">
        <p class="m-0">CRIMES CONTRA O IDOSO</p>
      </div>
      <div class="column col-3 text-box box-secondary mx-2" @click="$emit('nextPage', 4, {id: 31, title: 'CRUELDADE CONTRA ANIMAIS'})">
        <p class="m-0">CRUELDADE CONTRA ANIMAIS</p>
      </div>
      <div class="column col-3 text-box box-secondary mx-2" @click="$emit('nextPage', 4, {id: 69, title: 'OUTROS CRIMES'})">
        <p class="m-0">OUTROS CRIMES</p>
      </div>
    </div>
    <div class="columns">
      <div class="column col-3 text-box box-secondary p-4" @click="$emit('nextPage', 4, {id: 69, title: 'ESCOLA SEGURA'})">
        <p class="m-0">ESCOLA SEGURA</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .page-title {
    margin-bottom: 5vh;
  }
  .container {
    max-width:800px;
    margin-top: 10vh;
  }
  .m-0 {
    font-size:1rem;
  }
  @media (max-width: 640px) {
    .m-0 {
      font-size:0.8rem;
    }
    .page-title {
      font-size: 1.3rem;
    }
  }
  @media (max-width: 450px) {
    .m-0 {
      font-size:0.6rem;
    }
    .page-title {
      font-size: 1rem;
    }
  }
</style>