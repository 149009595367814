<template>
  <div>
    <h4 class="text-light page-title">ACOMPANHAR DENÚNCIA Nº: {{maskString(denunciation.protocol)}}</h4>
    <div class="columns">
        <div class="column col-10">
            <p class="text-light text-justify"><b>HISTÓRICO DA DENÚNCIA</b>: {{denunciation.description}}</p>
        </div>
        <div class="column col-10">
            <span v-for="(historie, idx) in denunciation.histories" :key="`${idx}_historie`">
                <p v-if="historie.responsible == 'Usuário'" class="bg-gray p-1 text-dark text-right">{{historie.comment}} :👮‍♂️</p>
                <p v-else class="bg-success p-1 text-light text-left">😀: {{historie.comment}}</p>
            </span>
        </div>
    </div>
    <div class="columns">
      <div class="column col-10">
        <div class="form-group">
          <label class="form-label text-left text-light" for="comment">Acrescentar informações</label>
          <textarea class="form-input" id="comment" v-model="appends.comment" placeholder="Acrescentar informações a denúncia" rows="4"></textarea>
        </div>
      </div>
      <div class="column col-10">
        <file-input :denunciation="appends" @files:change="filesChange" />
      </div>
    </div>
    <div class="columns mt-2">
      <div class="column col-3">
        <button class="btn btn-ligth"
          @click="$emit('nextPage', 1)"
        >
          VOLTAR
        </button>
      </div>
      <div class="column col-3">
        <button @click="saveDenunciation" class="btn btn-dark">
          ENVIAR
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FileInput from './FileInput.vue'
import axios from 'axios'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

const API_URL='https://disquedenuncia.pc.pb.gov.br'

export default {
  components:{
    "file-input": FileInput
  },
  props: {
    denunciation: {
        type: Object,
        required: true
    }
  },
  data() {
    return {
      appends: {
        comment: null,
        files: []
      },
      formData: null,
      denunciation_code: null
    }
  },
  methods: {
    filesChange(fileList) {
      this.appends.files = fileList;
      // console.log(fileList);
    },
    saveDenunciation() {
      let data = new FormData()
      this.assembleData(data, this.appends)
      this.$emit('showOverlay')
      axios.post(`${API_URL}/api/denuncias/historico/${this.denunciation.protocol}`, data, {headers: {'Content-Type': 'multipart/form-data'}})
        .then((res) => {
          this.$emit('newHistorie', res.data)
          Swal.fire({
            title: 'Sucesso!',
            text: `Adendo salvo com sucesso!`,
            icon: 'success',
            confirmButtonText: 'OK'
          })
          .then(result => {
            console.log(result)
            this.$emit('nextPage', 1)
          })
        })
        .catch(e => {
          // eslint-disable-next-line no-unused-vars
          Object.entries(e.response.data.errors).forEach(([key, error]) => {
            this.toast('error', `${error[0]}`)
          })
          // console.log(JSON.stringify(e.response.data.messages))
        })
        .finally(() => this.$emit('hideOverlay'))
    },
    assembleData(data, denunciation) {
      for (let key in denunciation) {
        if (denunciation[key] != null) {
          if (Array.isArray(denunciation[key])) {
            denunciation[key].forEach ((attribute_object, i) => {
              data.append(`${key}[${i}]`, denunciation[key][i])
              })
          } else {
            data.append(key, denunciation[key])
          }
        }
      }
      return data
    },
    async toast(icon, text) {
      await Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        icon: icon,
        title: text
      })
    },
    maskString(str) {
      let i = 0;
      let pattern = '####.####.####'
      const padded = pattern.replace(/#/g, () => {
          return str[i++];
      });
      return padded;
    }
  }

}
</script>

<style scoped>
  .btn {
    font-size: 1.5rem;
  }
  .btn-dark {
    background-color: rgb(57, 59, 59);
    border-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
  }
  .btn-ligth {
    background-color: rgb(206, 211, 211);
    border-color: rgb(0, 0, 0);
    color: rgb(0, 0, 0);
  }
  @media only screen and (max-width: 600px) {
    .btn {
      font-size: 1.0rem;
    }
  }
  @media only screen and (max-width:400px) {
    .btn {
      font-size: 0.7rem;
    }
  }
</style>
