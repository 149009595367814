<template>
    <div>
        <img id="anonimato" class="img-responsive p-centered" src="@/assets/images/app_197_logo_fundo_preto.png">
        <div class="btns-container">
            <div id="btns-container" class="columns">
                <div class="column col-12" style="margin-bottom:20px">
                    <button class="btn btn-secondary p-centered"  @click="$emit('nextPage', 4, {id: 69, title: 'ESCOLA SEGURA'})"><span class="text-dark">ESCOLA SEGURA</span></button>
                </div>
                <div class="column col-12" style="margin-bottom:20px">
                    <button class="btn btn-secondary p-centered" @click="$emit('nextPage', 2)"><span class="text-dark">OUTRAS DENÚNCIAS</span></button>
                </div>
                <div class="column col-12">
                    <button class="btn btn-secondary p-centered" @click="$emit('acompanharDenuncia')"><span class="text-dark">ACOMPANHAR DENÚNCIA</span></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style>

</style>