<template>
  <div class="form-box col-mx-auto">
    <h2 class="text-light page-title">DENUNCIAR SOBRE: {{denunciation_type.title}}</h2>
    <div class="columns">
      <div class="column col-12">
        <div class="form-group">
          <label class="form-label text-left text-light" for="descricao">Descrição</label>
          <textarea class="form-input" id="descricao" v-model="denunciation.description" placeholder="Descreva o fato detalhadamente com o máximo de dados possível" rows="6"></textarea>
        </div>
      </div>
      <div class="column col-12">
        <div class="form-group">
          <label class="form-label text-left text-light" for="endereco">Endereço</label>
          <input class="form-input" type="text" id="endereco" v-model="denunciation.address" placeholder="Ex: Rua, numero, ponto de referência...">
        </div>
      </div>
      <div class="column col-5">
        <div class="form-group">
          <label class="form-label text-left text-light" for="bairro">Bairro</label>
          <input class="form-input" type="text" id="bairro" v-model="denunciation.neighborhood" placeholder="Ex: Centro, Sítio"/>
        </div>
      </div>
      <div class="column col-7">
        <div class="form-group">
          <label class="form-label text-left text-light" for="cidade">Cidade</label>
          <select class="form-select" id="cidade" v-model="denunciation.city_id">
            <option :value="null">Selecione...</option>
            <template v-if="cities && cities.length">
              <option v-for="city in cities" :key="city.id" :value="city.id">{{city.title}}</option>
            </template>
          </select>
        </div>
      </div>
      <div class="column col-12">
        <file-input :denunciation="denunciation" @files:change="filesChange" />
      </div>
    </div>
    <div class="columns mt-2">
      <div class="column col-3">
        <button class="btn btn-ligth"
          @click="$emit('nextPage', 3)"
        >
          VOLTAR
        </button>
      </div>
      <div class="column col-3">
        <button @click="saveDenunciation" class="btn btn-dark">
          ENVIAR
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FileInput from './FileInput.vue'
import axios from 'axios'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

const API_URL='https://disquedenuncia.pc.pb.gov.br'

export default {
  components:{
    "file-input": FileInput
  },
  props: {
    denunciation_type: {
      type: Object,
      required: true
    },
    cities: {
      type: Array,
      required: true
    },
    neighborhoods: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      denunciation: {
        city_id: null,
        neighborhood_id: null,
        neighborhood: null,
        description: null,
        address: null,
        nature_id: null,
        files: []
      },
      formData: null,
      denunciation_code: null
    }
  },
  methods: {
    changeCity(event) {
      this.$emit('getBairros', event.target.value)
      this.denunciation.neighborhood_id = null
    },
    filesChange(fileList) {
      this.denunciation.files = fileList;
      // console.log(fileList);
    },
    saveDenunciation() {
      let data = new FormData()
      this.assembleData(data, this.denunciation)
      // console.log(this.denunciation)
      // for(let pair of data.entries()) {
      //   console.log(pair[0]+ ', '+ pair[1]);
      // }
      this.$emit('showOverlay')
      axios.post(`${API_URL}/api/denuncias/store`, data, {headers: {'Content-Type': 'multipart/form-data'}})
        .then((res) => {
          this.denunciation_code = this.maskString(res.data.protocol)
          Swal.fire({
            title: 'Sucesso!',
            text: `Protocolo da Denúncia: ${this.denunciation_code}`,
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#393B3B',
          })
          .then(result => {
            console.log(result)
            this.$emit('nextPage', 1)
          })
        })
        .catch(e => {
          // eslint-disable-next-line no-unused-vars
          if (e.response.status == 422){
            this.makeErrorToast(e.response.data.errors)
          } else if (e.response.status == 429 || e.response.status == 500) {
            // console.log(e.response.data.message)
            this.toast('error', `${e.response.data.message}`)
          }
        })
        .finally(() => this.$emit('hideOverlay'))
    },
    assembleData(data, denunciation) {
      for (let key in denunciation) {
        if (denunciation[key] != null) {
          if (Array.isArray(denunciation[key])) {
            denunciation[key].forEach ((attribute_object, i) => {
              data.append(`${key}[${i}]`, denunciation[key][i])
              })
          } else {
            data.append(key, denunciation[key])
          }
        }
      }
      return data
    },
    makeErrorToast(errors) {
        Object.values(errors).forEach((value) => {
          console.log(value[0])
              Swal.fire({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                icon: 'error',
                title: value[0]
              })
        })
    },
    async toast(icon, text) {
      await Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        icon: icon,
        title: text
      })
    },
    maskString(str) {
      let i = 0;
      let pattern = '####.####.####'
      const padded = pattern.replace(/#/g, () => {
          return str[i++];
      });
      return padded;
    }
  },
  mounted() {
    this.denunciation.nature_id = this.denunciation_type.id
  }

}
</script>

<style scoped>
  .container {
    max-width:800px;
  }
  .form-box {
    max-width: 800px;
  }
  .btn-dark {
    background-color: rgb(57, 59, 59);
    border-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
  }
  .btn-ligth {
    background-color: rgb(206, 211, 211);
    border-color: rgb(0, 0, 0);
    color: rgb(0, 0, 0);
  }
  .btn {
    font-size: 1.5rem;
  }
  @media only screen and (max-width: 600px) {
    .btn {
      font-size: 1.0rem;
    }
  }
  @media only screen and (max-width:400px) {
    .btn {
      font-size: 0.7rem;
    }
  }
</style>
