<template>
  <div id="app">
    <div class="container">
      <div class="logo p-centered">
        <img id="logo" class="img-responsive" src="@/assets/images/app_197_cabecalho_fundo_preto.png">
        <!-- <img v-else id="logo" class="img-responsive" src="@/assets/images/banner_197_2.png"> -->
      </div>
      <Page01 v-if="page == 1" @nextPage="nextPage" @acompanharDenuncia="acompanharDenuncia"/>
      <Page02 v-if="page == 2" @nextPage="nextPage"/>
      <Page03 v-if="page == 3" @nextPage="nextPage"/>
      <Page04 v-if="page == 4" @nextPage="nextPage"
              @getBairros="getNeighborhoods"
              @showOverlay="openOverlay"
              @hideOverlay="closeOverlay"
              :denunciation_type="denunciation_type"
              :cities="cities"
              :neighborhoods="neighborhoods"
      />
      <Page05 v-if="page == 5" @nextPage="nextPage"
              @showOverlay="openOverlay"
              @hideOverlay="closeOverlay"
              @newHistorie="newHistorie"
              :denunciation="denunciation"
      />
    </div>
    <div class="footer p-centered">
      <img class="img-responsive" src="@/assets/images/app_197_rodape_pagina_inicial.png">
    </div>
    <overlay :opened="opened"
      :visible="visible"
      :close-by-backdrop-click="false"
      :close-on-cancel="false"
      @closed="opened = visible = false"
    >
      <span>Aguarde, carregando...</span>
    </overlay>
  </div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import 'spectre.css/dist/spectre.min.css'
import 'spectre.css/dist/spectre-icons.min.css'
import Page01 from './components/Page_01.vue'
import Page02 from './components/Page_02.vue'
import Page03 from './components/Page_03.vue'
import Page04 from './components/Page_04.vue'
import Page05 from './components/Page_05.vue'
import Overlay from './components/Overlay.vue'
import axios from 'axios'

const API_URL='https://disquedenuncia.pc.pb.gov.br'

export default {
  name: 'App',
  components: {
    Page01,
    Page02,
    Page03,
    Page04,
    Page05,
    Overlay
  },
  data(){
    return {
      page: 1,
      denunciation_type: null,
      cities: [],
      neighborhoods: [],
      opened: false,
      visible: false,
      denunciation: null,
    }
  },
  methods: {
    async acompanharDenuncia() {
      const { value: protocolo } = await Swal.fire({
        title: 'Insira o protocolo da denúncia',
        input: 'text',
        inputLabel: 'Protocolo da Denúncia',
        inputPlaceholder: 'Protocolo da denúncia que deseja acompanhar',
        inputValue: '',
        confirmButtonColor: '#393B3B',
      })
      if (protocolo) {
        this.openOverlay()
        axios.get(`${API_URL}/api/denuncias/${protocolo}`)
        .then((res) => {
          console.log(res)
          this.denunciation = res.data
          Swal.fire({
            title: 'Sucesso!',
            text: `Denúncia carregada com sucesso: ${protocolo}`,
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#393B3B',
          })
          .then(result => {
            console.log(result)
            this.nextPage(5)
          })
        })
        .catch(e => {
          // eslint-disable-next-line no-unused-vars
          // Object.entries(e.response.data.errors).forEach(([key, error]) => {
          //   this.toast('error', `${error[0]}`)
          // })
          if (e.response.data.message) {
            this.toast('error', `${e.response.data.message}`)
          }
        })
        .finally(() => this.closeOverlay())
      } else {
        Swal.fire({
          title: 'Nenhum protocolo informado',
          icon: 'error',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          },
        })
      }
    },
    nextPage(page, payload = null) {
        this.page = page
        if (payload && page == 4) {
          this.denunciation_type = payload
          this.getCities()
        } else if (payload && page == 5) {
          this.denunciation = payload
        }
    },
      previousPage(page) {
        this.page = page
    },
    getCities() {
      axios.get(`${API_URL}/api/cidades?state_id=15`)
        .then((res) => {
          this.cities = res.data
        })
        .catch(e => {
          console.log(JSON.stringify(e))
        })
    },
    getNeighborhoods(event) {
      console.log(event)
      axios.get(`${API_URL}/api/bairros?city_id=${event}`)
        .then((res) => {
          this.neighborhoods = res.data
        })
        .catch(e => {
          console.log(JSON.stringify(e))
        })
    },
    saveDenunciation(event) {
      console.log(event)
      this.openOverlay()
      setTimeout(()=>{
        console.log('SetTimeout')
        this.closeOverlay()
      },2000)
    },
    openOverlay() {
      this.opened = true
      this.visible = true
    },
    closeOverlay() {
      this.opened = false
      this.visible = false
    },
    async toast(icon, text) {
      await Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        icon: icon,
        title: text
      })
    },
    newHistorie(historie) {
      this.denunciation.histories.push(historie)
    }
  },
  created() {
    axios.get(`${API_URL}/api/check`)
      .then((res) => {
        console.log(res)
      })
      .catch(res => {
        console.log(res)
        Swal.fire({
          title: 'Erro!',
          text: 'Desculpe, a aplicação está fora do ar.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
  }
}
</script>

<style>
  #app{
    min-height: 100vh;
    width: 100vw;
    display: flex;
    /* justify-content: top; */
    align-items: center;
    flex-direction: column;
    background-color: #333333;
    overflow-y: scroll;
  }
  .container {
    margin-bottom: 10vh;
    width: 95vw;
    flex-grow: 1;
  }
  .logo{
    max-width: 800px;
  }
  .footer{
    max-width: 700px;
    width: 90vw;
    align-items: end;
  }
  #anonimato{
    margin-top: 10vh;
    width: 50vw;
    max-width: 300px;
  }
  #btns-container{
    margin-top: 10vh;
  }
  .page-title{
    text-align: center;
    margin-top: 30px;
  }
  .text-box {
    border-radius: 10px;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 10px;
  }
  .text-box > p{
    margin: 8px;
    font-size: 0.5rem;
  }
  .columns{
    display: flex;
    align-items: stretch;
    justify-content: center;
    text-align: center;
  }
  .column{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .box-red{
    background-color:red;
    color:white;
    font-weight:bold;
    cursor: pointer;
  }
</style>
