<template>
    <div>
        <h2 class="text-light page-title">Informações</h2>
        <!-- <div class="container"> -->
            <div class="text-box column col-xl-6 col-lg-6 col-md-10 col-mx-auto">
                <p class="paragraph">
                    Por meio do Disque Denúncia virtual da Polícia Civil da Paraíba ou pelo número 197 você poderá contribuir
                    com a sua segurança e da sociedade, denunciando autores dos mais diversos crimes como: homicídio, tráfico
                    de drogas, roubo ou furto, estelionato, violência contra a mulher, o idoso, meio ambiente, fugitivos,
                    entre outros.
                </p>
                <p class="paragraph">
                    <span class="text-error"><b>É importante</b></span> que antes de denunciar procure coletar o máximo de informações sobre o crime e o autor, tais
                    como: data do fato, endereço completo, ponto de referência, nome e características físicas dos envolvidos,
                    placa de veículos, vídeos, fotos e outros meios de prova.
                </p>
                <p class="paragraph">
                    <b>Mas, atenção, caso você seja uma vítima procure a Delegacia de Polícia Civil mais próxima e registre a
                    ocorrência</b>, pois exames, perícias ou testemunhas poderão ser necessários para a instauração de
                    procedimento policial.
                </p>
                <p class="paragraph">
                    Colabore com a Polícia no combate à criminalidade. O Sigilo é absoluto e o anonimato  garantido.
                </p>
            </div>
        <!-- </ div> -->
        <button class="btn btn-dark p-centered" @click="$emit('nextPage', 3)"><b>CONTINUAR</b></button>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
     .container {
        max-width:800px;
     }
     .btn-dark {
        background-color: rgb(57, 59, 59);
        border-color: rgb(255, 255, 255);
        color: rgb(255, 255, 255);
    }
     .paragraph {
        font-size:0.8rem;
        text-align:justify;
     }
     .text-box {
        max-width: 800px;
     }
</style>